//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import VueSlickCarousel from 'vue-slick-carousel';
import 'vue-slick-carousel/dist/vue-slick-carousel.css';

export default {
    components: {
        VueSlickCarousel,
    },
    props: {
        slides: {
            type: Array,
            required: true,
        },
        mobile: {
            type: Boolean,
            default: false,
        },
        noArrow: {
            type: Boolean,
            default: false,
        },
    },
    data() {
        return {
            mobileSetting: {
                slidesToShow: 1,
                slidesToScroll: 1,
                initialSlide: 0,
                dots: true,
                arrows: false,
            },
            setting: {
                centerMode: true,
                centerPadding: '0',
                slidesToShow: 2,
                initialSlide: 1,
                speed: 500,
                rows: 1,
                slidesPerRow: 1,
                rtl: this.$vuetify.rtl,
                dots: true,
                arrows: false,
                autoplay: true,
                autoplaySpeed: 5000,
                pauseOnDotsHover: true,
                pauseOnFocus: true,
                pauseOnHover: true,
                swipeToSlide: true,
                accessibility: true,
                edgeFriction: 0.65,
                touchThreshold: 5,
                infinite: true,
                responsive: [],
                variableWidth: true,
            },

            responsiveSetting: [
                {
                    breakpoint: 900,
                    settings: {
                        slidesToShow: 2,
                        slidesToScroll: 1,
                        initialSlide: 1,
                        dots: true,
                        arrows: false,
                    },
                },
                {
                    breakpoint: 530,
                    settings: {
                        slidesToShow: 1,
                        slidesToScroll: 1,
                        initialSlide: 1,
                        dots: true,
                        arrows: false,
                    },
                },
            ],
            areResonsiveSettingsApplied: false,
            swiping: false,
        };
    },
    methods: {
        applyResponsiveSettings() {
            this.setting.responsive = this.responsiveSetting;
            this.areResonsiveSettingsApplied = true;
        },
        initHandler() {
            if (!this.areResonsiveSettingsApplied) {
                this.applyResponsiveSettings();
            }
        },
        setCarouselSwiping(state) {
            this.swiping = state;
        },
        getProps(item) {
            return Object.entries(this.cardProps).reduce((acc, [key, value]) => {
                if (value === 'slideObject') {
                    acc[key] = item;
                } else {
                    acc[key] = value;
                }
                return acc;
            }, {});
        },
    },
};
